import { GetterTree } from 'vuex/types';
import { State } from "@/store/types";
import { AppState } from "./state";

const getters: GetterTree<AppState, State> = {
  isLoading: app => app.loading,
  appAlias: app => app.config.alias,
  allowDeposit: app => app.config.allowDeposit,
  appUsersLastMessagesKeyPrefix: app => app.config.usersLastMessagesKeyPrefix,
  modalProps: app => app.modalProps,
  nextUrl: app => app.nextUrl,
  pageData: app => app.pageData,
  isDesktop: app => app.device.isDesktop,
  isTablet: app => app.device.isTablet,
  isMobile: app => app.device.isMobile,
  game: app => app.game,
  topBannerHeight: app => app.topBannerHeight,
  bottomBannerHeight: app => app.bottomBannerHeight,
  adsLoaded: app => app.adsLoaded,
  firstAdLoaded: app => app.firstAdLoaded,
  maintenanceMode: app => app.maintenanceMode,
  appVersion: app => app.appVersion,
  appVisible: app => app.appVisible,
  appOffline: app => app.appOffline,
};

export default getters;
