
  import Vue from 'vue';
  import PwaSlider from "@/components/common/PwaSlider.vue";
  import {ROUTES} from "@/router/routes";

  export const PwaAllMinigamesViewTypes = {
    SLIDER: 'slider',
    ALL: 'all',
  };

  export default Vue.extend({
    name: "pwa-all-minigames",
    props: {
      games: {
        type: [Array, Object],
        required: true
      },
      signUp: {
        type: Function
      },
      sliderAutoplayDelay: {
        type: Number,
        required: false,
        default: 0,
      },
      viewType: {
        type: String,
        required: false,
        default: PwaAllMinigamesViewTypes.SLIDER,
      },
      filterGameAlias: {
        type: String,
        required: false,
        default: '',
      },
    },
    components: {
      PwaSlider
    },
    data() {
      return {
        minigameRouteName: ROUTES.minigame.name,
        viewTypes: PwaAllMinigamesViewTypes,
      }
    },
    computed: {
      gamesFiltered(): Array<any> {
        return this.filterGameAlias ? this.games.filter((game: any) => game.alias != this.filterGameAlias) : this.games;
      },
      isSmallView(): boolean {
        return this.viewType === PwaAllMinigamesViewTypes.ALL && this.isMobile;
      },
    },
  });
