
  import Vue from "vue";
  import pageMixin from "@/mixins/page";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";

  export default Vue.extend({
    name: "Offline",
    mixins: [pageMixin],
    computed: {
      breadcrumb(): BreadcrumbItemType[] {
        return [{labelId: BreadcrumbItems.offline.labelId}];
      },
    },
  });
