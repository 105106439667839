
  import Vue from 'vue';
  import PwaSlider from "@/components/common/PwaSlider.vue";
  import {ROUTES} from "@/router/routes";
  import PwaIcon from '@/components/common/PwaIcon.vue';
  import Swiper from 'swiper';

  export const PwaAllGamesViewTypes = {
    SLIDER: 'slider',
    ALL: 'all',
  };

  export default Vue.extend({
    name: "pwa-all-games",
    props: {
      gameCategories: {
        type: Array,
        required: true
      },
      games: {
        type: [Array, Object],
        required: true
      },
      signUp: {
        type: Function
      },
      sliderAutoplayDelay: {
        type: Number,
        required: false,
        default: 0,
      },
      showGameName: {
        type: Boolean,
        required: false,
        default: true,
      },
      showGamePlatform: {
        type: Boolean,
        required: false,
        default: true,
      },
      allowNavigateToGame: {
        type: Boolean,
        required: false,
        default: true,
      },
      showGameFilters: {
        type: Boolean,
        required: false,
        default: false,
      },
      viewType: {
        type: String,
        required: false,
        default: PwaAllGamesViewTypes.SLIDER,
      },
    },
    components: {
      PwaSlider,
      PwaIcon
    },
    data() {
      return {
        selectedGameCategoryCode: '',
        filteredGames: this.games,
        processedGameCategories: [],
        viewTypes: PwaAllGamesViewTypes,
        routes: ROUTES,
      }
    },
    computed: {
      isSmallView(): boolean {
        return this.viewType === PwaAllGamesViewTypes.ALL && this.isMobile;
      },
    },
    methods: {
      getGameCategoriesProcessed(gameCategories: any[]) {
        const descriptionVar = `description_${this.currentLang}`;
        const gameCategoriesProcessed: any[] = gameCategories.map(gameCategory => ({
          ...gameCategory,
          description: gameCategory[descriptionVar]
        }));
        gameCategoriesProcessed.sort((gameCategory1, gameCategory2) => gameCategory1.description.localeCompare(gameCategory2.description));

        return gameCategoriesProcessed;
      },
      onSelectFilter(code: any) {
        this.selectedGameCategoryCode = code;
        if (code) {
          this.filteredGames = this.games.filter(({category_code}: any) => category_code == code);
        } else {
          this.filteredGames = this.games;
        }

        const allGamesSlider: any = this.$refs.allGamesSlider as any;
        const swiper: Swiper = allGamesSlider && allGamesSlider.swiper ? allGamesSlider.swiper : null;
        if (swiper) {
          this.$nextTick(() => {
            swiper.slideTo(0);
            swiper.update();
          });
        }
      },
      getUniquePlatforms(game: any) {
        const platformNames: any = {};
        const listPlatforms: any[] = [];
        const platforms = game.platforms || [];

        platforms.forEach(({platform_name, icon_platform, game_id}: any) => {
          if (!platformNames[platform_name]) {
            platformNames[platform_name] = true;
            listPlatforms.push({
              name: platform_name,
              icon: icon_platform,
              game_id: game_id
            });
          }
        });

        return listPlatforms;
      },
    },
    watch: {
      currentLang() {
        this.getGameCategoriesProcessed(this.gameCategories);
      },
      games() {
        this.filteredGames = this.games;
        this.selectedGameCategoryCode = '';
      }
    }
  });
