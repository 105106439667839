export const USER_RANKINGS_PERIOD = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
};

export const USER_RANKINGS_TYPE = {
  WIN_RATE: 'win_rate',
  PRIZE: 'prize',
  WINS: 'wins',
  STAKE: 'stake',
};

export const GAMES_ALIAS = {
  FIFA_23: 'fifa-23',
  EA_SPORTS_FC_24: 'ea-sports-fc-24',
  EA_SPORTS_FC_25: 'ea-sports-fc-25',
  EIGHT_BALL_POOL: '8-ball-pool',
  COD_MOBILE: 'cod-mobile',
  COD_MODERN_WARFARE_3: 'cod-modern-warfare-3',
  COD_WARZONE: 'cod-warzone',
  COD_BLACK_OPS_6: 'cod-black-ops-6',
  HEAD_BALL_2: 'head-ball-2',
  NBA_2K24: 'nba-2k24',
  BOWLING_KING: 'bowling-king',
  SOCCER_STARS: 'soccer-stars',
  PING_PONG_FURY: 'ping-pong-fury',
  EFOOTBALL_2024: 'efootball-2024',
  F1_23: 'f1-23',
  EA_FC_24_MOBILE: 'ea-fc-24-mobile',
  UFC_5: 'ufc-5',
  MINIGOLF_PLATO: 'minigolf-plato',
  FOOTBALL_STRIKE: 'football-strike',
  WARZONE_MOBILE: 'warzone-mobile',
  FREE_FIRE: 'free-fire',
  STOP_2: 'stop-2',
  RUMBLE_STARS: 'rumble-stars',
  MINI_TENNIS: 'mini-tennis',
  CLASH_ROYALE: 'CRO',
  BRAWL_STARS: 'BrawlStars',
  UNO: 'uno',
  PARCHIS_STAR: 'parchis-star',
  CUATRO_EN_RAYA: '4-en-raya',
};

export const GAMES_URLS: Record<string, boolean> = {
  'link.clashroyale.com': true,
  'link.brawlstars.com': true,
  'rumblestarssoccer.page.link': true,
}
